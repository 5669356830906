<template>
  <div class="pbf">
     <BrandTabGrowers
        v-if="type == 'brands'"
        :inurl="`/${props.type}/${props.id}/growers`"
        :brand-id="props.id"
      />
      <BrandTabGrowers
          v-else
          :inurl="`/${props.type}/${props.id}/growers`"
          :product-id="props.id"
        />
  </div>
</template>

<script setup lang="ts">
const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  },
  type: {
    type: String
  }
})
</script>